<template>
  <div style="background-color: #f7f7f7">
    <headers></headers>
    <div v-if="enabled" class="main1200" style="margin-bottom: 0">
      <div class="appraise_hears" >
        <img src="@/assets/img/hjgs.png" alt="" style="width: 700px;margin-left: -350px;"/>
      </div>
    </div>
    <div v-if="enabled" class="main12301" :style="{ height: highr + 'px' }">
      <div class="cuosn">
        <img src="@/assets/img/award_zpzl.png"  style="margin-left: -245px;width: auto"/>
      </div>
      <div class="cuosn">
        <img src="@/assets/img/11@1x.png" />
      </div>

      <el-table
        :data="tableData1"
        :header-cell-style="{ color: '#81BAAB', backgroundColor: '#EBF2F0' }"
        class="tabss"
        style="width: 100%"
        :cell-class-name="addClass"
      >
        <el-table-column type="indexss" align="center" label="" width="10">
        </el-table-column>
        <el-table-column type="index" align="center" label="序号" width="100">
        </el-table-column>
        <el-table-column type="indexss" align="center" label="" width="10">
        </el-table-column>
        <el-table-column
          prop="bmzpbh"
          align="center"
          label="作品编号"
          width="100"
        >
        </el-table-column>
        <el-table-column type="indexss" align="center" label="" width="50">
        </el-table-column>
        <el-table-column
          prop="trueName"
          label="作者姓名"
          width="200"
          style="text-indent: 20px"
        >
        </el-table-column>
        <el-table-column prop="yuanXiaoName" label="所在院校">
        </el-table-column>
        <el-table-column prop="zpMingCheng" label="作品名称"> </el-table-column>
      </el-table>
      <!--  -->
      <div class="cuosn" style="margin-top: 20px">
        <img src="@/assets/img/22@1x.png" />
      </div>
      <el-table
        :data="tableData2"
        :header-cell-style="{ color: '#81BAAB', backgroundColor: '#EBF2F0' }"
        class="tabss"
        style="width: 100%"
        :cell-class-name="addClass"
      >
        <el-table-column type="indexss" align="center" label="" width="10">
        </el-table-column>
        <el-table-column type="index" align="center" label="序号" width="100">
        </el-table-column>
        <el-table-column type="indexss" align="center" label="" width="10">
        </el-table-column>
        <el-table-column
          prop="bmzpbh"
          align="center"
          label="作品编号"
          width="100"
        >
        </el-table-column>
        <el-table-column type="indexss" align="center" label="" width="50">
        </el-table-column>
        <el-table-column
          prop="trueName"
          label="作者姓名"
          width="200"
          style="text-indent: 20px"
        >
        </el-table-column>
        <el-table-column prop="yuanXiaoName" label="所在院校">
        </el-table-column>
        <el-table-column prop="zpMingCheng" label="作品名称"> </el-table-column>
      </el-table>
      <!--  -->
      <div class="cuosn" style="margin-top: 20px">
        <img src="@/assets/img/33@1x.png" />
      </div>
      <el-table
        :data="tableData3"
        :header-cell-style="{ color: '#81BAAB', backgroundColor: '#EBF2F0' }"
        class="tabss"
        style="width: 100%"
        :cell-class-name="addClass"
      >
        <el-table-column type="indexss" align="center" label="" width="10">
        </el-table-column>
        <el-table-column type="index" align="center" label="序号" width="100">
        </el-table-column>
        <el-table-column type="indexss" align="center" label="" width="10">
        </el-table-column>
        <el-table-column
          prop="bmzpbh"
          align="center"
          label="作品编号"
          width="100"
        >
        </el-table-column>
        <el-table-column type="indexss" align="center" label="" width="50">
        </el-table-column>
        <el-table-column
          prop="trueName"
          label="作者姓名"
          width="200"
          style="text-indent: 20px"
        >
        </el-table-column>
        <el-table-column prop="yuanXiaoName" label="所在院校">
        </el-table-column>
        <el-table-column prop="zpMingCheng" label="作品名称"> </el-table-column>
      </el-table>
      <!--  -->
      <div class="cuosn" style="margin-top: 20px">
        <img src="@/assets/img/youxiu2@1x.png" />
      </div>
      <el-table
          :data="tableData4"
          :header-cell-style="{ color: '#81BAAB', backgroundColor: '#EBF2F0' }"
          class="tabss"
          style="width: 100%"
          :cell-class-name="addClass"
      >
        <el-table-column type="indexss" align="center" label="" width="10">
        </el-table-column>
        <el-table-column type="index" align="center" label="序号" width="100">
        </el-table-column>
        <el-table-column type="indexss" align="center" label="" width="10">
        </el-table-column>
        <el-table-column
            prop="bmzpbh"
            align="center"
            label="作品编号"
            width="100"
        >
        </el-table-column>
        <el-table-column type="indexss" align="center" label="" width="50">
        </el-table-column>
        <el-table-column
            prop="trueName"
            label="作者姓名"
            width="200"
            style="text-indent: 20px"
        >
        </el-table-column>
        <el-table-column prop="yuanXiaoName" label="所在院校">
        </el-table-column>
        <el-table-column prop="zpMingCheng" label="作品名称"> </el-table-column>
      </el-table>

      <!--  -->
      <div class="cuosn" style="margin-top: 20px">
        <img src="@/assets/img/award_yxzdls.png" style="margin-left: -216px;width: auto"/>
      </div>
      <el-table
          :data="tableData5"
          :header-cell-style="{ color: '#81BAAB', backgroundColor: '#EBF2F0' }"
          class="tabss"
          style="width: 100%"
          :cell-class-name="addClass"
      >
        <el-table-column type="indexss" align="center" label="" width="100">
        </el-table-column>
        <el-table-column type="index" align="center" label="序号" width="200">
        </el-table-column>
        <el-table-column type="indexss" align="center" label="" width="150">
        </el-table-column>
        <el-table-column
            prop="trueName"
            label="作者姓名"
            width="300"
            style="text-indent: 20px"
        >
        </el-table-column>
        <el-table-column prop="yuanXiaoName" label="所在院校">  </el-table-column>
      </el-table>
    </div>
    <footers></footers>
  </div>
</template>
  <script>
import headers from "@/components/headers.vue";
import footers from "@/components/footers.vue";
export default {
  name: "appraise",
  data() {
    return {
      // 是否开始
      enabled:true,
      tableData1: [{bmzpbh: "0370",trueName: "汪嵇钰",yuanXiaoName: "江苏旅游职业学院",zpMingCheng: "我的家乡团庄"},
        {bmzpbh: "0980",trueName: "戚思敏",yuanXiaoName: "南京特殊教育师范学院",zpMingCheng: "倒影·二十四节气"},
        {bmzpbh: "0990",trueName: "刘宇旋",yuanXiaoName: "南京艺术学院",zpMingCheng: "乡语"},
        {bmzpbh: "1105",trueName: "花会雨",yuanXiaoName: "江苏师范大学",zpMingCheng: "走进乡村看小康"},
        {bmzpbh: "1332",trueName: "黄泊涵",yuanXiaoName: "南京师范大学",zpMingCheng: "黄龙岘·小村新印象"},
        {bmzpbh: "1335",trueName: "李晨曦",yuanXiaoName: "南京传媒学院",zpMingCheng: "山谷里的时光"},
        {bmzpbh: "1409",trueName: "杨曜源",yuanXiaoName: "南京艺术学院",zpMingCheng: "绿之交响乐"},
        {bmzpbh: "1615",trueName: "光  昇",yuanXiaoName: "南京大学",zpMingCheng: "水乡晚舟"},
        {bmzpbh: "1671",trueName: "胡琳冉",yuanXiaoName: "南京艺术学院",zpMingCheng: "暖阳下的佘村"},
        {bmzpbh: "1723",trueName: "王思淇",yuanXiaoName: "扬州大学",zpMingCheng: "城乡“谐”奏曲"},
      ], //一等奖
      tableData2: [{bmzpbh: "0238",trueName: "陈海粟",yuanXiaoName: "江苏师范大学",zpMingCheng: "苍翠居野"},
        {bmzpbh: "0299",trueName: "钱  浩",yuanXiaoName: "南京艺术学院",zpMingCheng: "小溪轻语中的乡间诗"},
        {bmzpbh: "0403",trueName: "时林雨",yuanXiaoName: "镇江市高等专科学校",zpMingCheng: "这里的风光静悄悄"},
        {bmzpbh: "0423",trueName: "赵昕怡",yuanXiaoName: "苏州科技大学",zpMingCheng: "水韵丰年"},
        {bmzpbh: "0434",trueName: "刘  悦",yuanXiaoName: "常州工业职业技术学院",zpMingCheng: "仙姑村烈士陵园"},
        {bmzpbh: "0450",trueName: "曹  博",yuanXiaoName: "南京传媒学院",zpMingCheng: "漆绘金陵·苏韵乡村"},
        {bmzpbh: "0467",trueName: "宁培林",yuanXiaoName: "南京传媒学院",zpMingCheng: "山间"},
        {bmzpbh: "0604",trueName: "於季亿",yuanXiaoName: "江苏经贸职业技术学院",zpMingCheng: "观音殿之夏"},
        {bmzpbh: "0714",trueName: "赵紫馨",yuanXiaoName: "苏州工艺美术职业技术学院",zpMingCheng: "渔船的故事"},
        {bmzpbh: "0727",trueName: "王文清",yuanXiaoName: "南通师范高等专科学校",zpMingCheng: "澎湃家乡"},
        {bmzpbh: "1296",trueName: "薛晶晶",yuanXiaoName: "淮阴工学院",zpMingCheng: "池塘"},
        {bmzpbh: "1336",trueName: "胡俊辉",yuanXiaoName: "盐城师范学院",zpMingCheng: "茅山印象"},
        {bmzpbh: "1433",trueName: "王  通",yuanXiaoName: "苏州工艺美术职业技术学院",zpMingCheng: "江村一貌"},
        {bmzpbh: "1441",trueName: "卢  意",yuanXiaoName: "南京艺术学院",zpMingCheng: "乡村的雨天"},
        {bmzpbh: "1544",trueName: "曾子诺",yuanXiaoName: "连云港师范高等专科学校",zpMingCheng: "东海渔歌·黄窝印象"},
        {bmzpbh: "1555",trueName: "吴  昱",yuanXiaoName: "南京艺术学院",zpMingCheng: "荷花寺"},
        {bmzpbh: "1600",trueName: "王海堯",yuanXiaoName: "盐城幼儿师范高等专科学校",zpMingCheng: "乡村新篇章"},
        {bmzpbh: "1626",trueName: "程佳柔",yuanXiaoName: "盐城幼儿师范高等专科学校",zpMingCheng: "小村十六景"},
        {bmzpbh: "1724",trueName: "冯  玺",yuanXiaoName: "南京大学",zpMingCheng: "新象"},

      ], //二等奖
      tableData3: [{bmzpbh: "0200",trueName: "王  园",yuanXiaoName: "宿迁学院",zpMingCheng: "小桥流水人家"},
        {bmzpbh: "0268",trueName: "翟姝婧",yuanXiaoName: "盐城师范学院",zpMingCheng: "童年的记忆"},
        {bmzpbh: "0325",trueName: "府宇峰",yuanXiaoName: "南京艺术学院",zpMingCheng: "佘村风光"},
        {bmzpbh: "0358",trueName: "李丽琴",yuanXiaoName: "南京艺术学院",zpMingCheng: "响堂·未来畅想"},
        {bmzpbh: "0437",trueName: "朱婧瑞",yuanXiaoName: "常州工业职业技术学院",zpMingCheng: "新四军战斗过的地方"},
        {bmzpbh: "0512",trueName: "朱笑岑",yuanXiaoName: "淮阴师范学院",zpMingCheng: "新乡绿田"},
        {bmzpbh: "0622",trueName: "刘音池",yuanXiaoName: "宿迁学院",zpMingCheng: "苏北新村小景"},
        {bmzpbh: "0668",trueName: "明  甜",yuanXiaoName: "江苏师范大学",zpMingCheng: "集市"},
        {bmzpbh: "0907",trueName: "林  简",yuanXiaoName: "南京师范大学",zpMingCheng: "江南新村"},
        {bmzpbh: "1002",trueName: "汤子涵",yuanXiaoName: "东南大学成贤学院",zpMingCheng: "椿野初见"},
        {bmzpbh: "1079",trueName: "朱俊豪",yuanXiaoName: "江苏师范大学",zpMingCheng: "倪园风采"},
        {bmzpbh: "1137",trueName: "唐一丹",yuanXiaoName: "南京师范大学",zpMingCheng: "佘村新颜"},
        {bmzpbh: "1146",trueName: "李曹宇",yuanXiaoName: "南京艺术学院",zpMingCheng: "响堂·一塘风光"},
        {bmzpbh: "1236",trueName: "王英姿",yuanXiaoName: "南京艺术学院",zpMingCheng: "雨"},
        {bmzpbh: "1269",trueName: "李  蔓",yuanXiaoName: "江苏建筑职业技术学院",zpMingCheng: "梦泽乡境"},
        {bmzpbh: "1327",trueName: "周禹卓",yuanXiaoName: "南京特殊教育师范学院",zpMingCheng: "老街·雨巷"},
        {bmzpbh: "1354",trueName: "刘雨杭",yuanXiaoName: "苏州工艺美术职业技术学院",zpMingCheng: "树影"},
        {bmzpbh: "1368",trueName: "凌文宣",yuanXiaoName: "南京师范大学",zpMingCheng: "青山绿水黄龙岘"},
        {bmzpbh: "1486",trueName: "顾静蕊",yuanXiaoName: "南京特殊教育师范学院",zpMingCheng: "热血铸丰碑"},
        {bmzpbh: "1510",trueName: "庞  璇",yuanXiaoName: "南京艺术学院",zpMingCheng: "青隅留色"},
        {bmzpbh: "1527",trueName: "刘积琦",yuanXiaoName: "江苏建筑职业技术学院",zpMingCheng: "塔山画卷·红色传承与乡村新貌"},
        {bmzpbh: "1535",trueName: "李晓娟",yuanXiaoName: "南京特殊教育师范学院",zpMingCheng: "日落余晖"},
        {bmzpbh: "1562",trueName: "汪小玥",yuanXiaoName: "南京艺术学院",zpMingCheng: "途经火炬村"},
        {bmzpbh: "1580",trueName: "丁振耀",yuanXiaoName: "苏州健雄职业技术学院",zpMingCheng: "午后杨漕"},
        {bmzpbh: "1650",trueName: "薄李源",yuanXiaoName: "江苏第二师范学院",zpMingCheng: "立足铁骨气自雄"},
        {bmzpbh: "1701",trueName: "刘沐时",yuanXiaoName: "南京艺术学院",zpMingCheng: "暖阳下的新村庄"},
        {bmzpbh: "1721",trueName: "胥  芹",yuanXiaoName: "盐城幼儿师范高等专科学校",zpMingCheng: "涟壁诗"},

      ], //三等奖
      tableData4: [{bmzpbh: "0077",trueName: "周亦婷",yuanXiaoName: "无锡太湖学院",zpMingCheng: "街道"},
        {bmzpbh: "0205",trueName: "顾忆侬",yuanXiaoName: "宿迁学院",zpMingCheng: "水巷·停舟待客"},
        {bmzpbh: "0211",trueName: "王  媛",yuanXiaoName: "宿迁学院",zpMingCheng: "山介小镇"},
        {bmzpbh: "0285",trueName: "章  奕",yuanXiaoName: "南京艺术学院",zpMingCheng: "惬意时光"},
        {bmzpbh: "0315",trueName: "周  蕊",yuanXiaoName: "南京艺术学院",zpMingCheng: "响堂奇遇山谷"},
        {bmzpbh: "0323",trueName: "王  奔",yuanXiaoName: "南京艺术学院",zpMingCheng: "池鱼戏水"},
        {bmzpbh: "0348",trueName: "姜苗苗",yuanXiaoName: "江苏旅游职业学院",zpMingCheng: "出海"},
        {bmzpbh: "0356",trueName: "倪玮锶",yuanXiaoName: "南京师范大学",zpMingCheng: "青·律"},
        {bmzpbh: "0379",trueName: "张雨轩",yuanXiaoName: "南京艺术学院",zpMingCheng: "悠幽"},
        {bmzpbh: "0383",trueName: "徐家富",yuanXiaoName: "镇江市高等专科学校",zpMingCheng: "岁月的赞歌"},
        {bmzpbh: "0455",trueName: "张彦萍",yuanXiaoName: "南京特殊教育师范学院",zpMingCheng: "墨香古巷"},
        {bmzpbh: "0457",trueName: "何权铠",yuanXiaoName: "镇江市高等专科学校",zpMingCheng: "水台村的戏台"},
        {bmzpbh: "0725",trueName: "程家亮",yuanXiaoName: "南通师范高等专科学校",zpMingCheng: "古镇"},
        {bmzpbh: "0757",trueName: "丁仲豪",yuanXiaoName: "常州大学",zpMingCheng: "无锡水乡"},
        {bmzpbh: "0791",trueName: "杨婧云",yuanXiaoName: "东南大学成贤学院",zpMingCheng: "大美如皋·风韵犹存"},
        {bmzpbh: "0890",trueName: "付永琪",yuanXiaoName: "宿迁学院",zpMingCheng: "故乡微明"},
        {bmzpbh: "0942",trueName: "杨欣雨",yuanXiaoName: "南京师范大学",zpMingCheng: "黄龙岘公路"},
        {bmzpbh: "0987",trueName: "刘  绅",yuanXiaoName: "江苏建筑职业技术学院",zpMingCheng: "四季涵韵"},
        {bmzpbh: "1029",trueName: "胡昭皓",yuanXiaoName: "南京传媒学院",zpMingCheng: "古村新貌"},
        {bmzpbh: "1052",trueName: "卢雷鸣",yuanXiaoName: "江苏财经职业技术学院",zpMingCheng: "芦家滩今昔"},
        {bmzpbh: "1103",trueName: "黄佳宁",yuanXiaoName: "南京师范大学",zpMingCheng: "荷香南门村"},
        {bmzpbh: "1158",trueName: "许均晗",yuanXiaoName: "南京师范大学",zpMingCheng: "桥上看风景"},
        {bmzpbh: "1164",trueName: "吴张睿儿",yuanXiaoName: "南通师范高等专科学校",zpMingCheng: "夕照水乡"},
        {bmzpbh: "1214",trueName: "黄鸣鹤",yuanXiaoName: "东南大学",zpMingCheng: "水木潺湲"},
        {bmzpbh: "1226",trueName: "龚格圆",yuanXiaoName: "南京特殊教育师范学院",zpMingCheng: "田园无限"},
        {bmzpbh: "1229",trueName: "沈心怡",yuanXiaoName: "东南大学成贤学院",zpMingCheng: "乡村遗梦"},
        {bmzpbh: "1268",trueName: "陶诗彤",yuanXiaoName: "淮阴工学院",zpMingCheng: "乡风荷韵"},
        {bmzpbh: "1313",trueName: "厉澈璠",yuanXiaoName: "江苏建筑职业技术学院",zpMingCheng: "红色张后屯·青春奋斗路"},
        {bmzpbh: "1339",trueName: "苗  田",yuanXiaoName: "南通大学",zpMingCheng: "炙热乡情"},
        {bmzpbh: "1349",trueName: "丁家盛",yuanXiaoName: "江苏师范大学",zpMingCheng: "早秋余晖"},
        {bmzpbh: "1385",trueName: "郑  伟",yuanXiaoName: "南京师范大学",zpMingCheng: "黄龙岘池塘"},
        {bmzpbh: "1405",trueName: "张文静",yuanXiaoName: "江苏建筑职业技术学院",zpMingCheng: "红色记忆"},
        {bmzpbh: "1419",trueName: "陈思源",yuanXiaoName: "淮阴工学院",zpMingCheng: "青青河畔村"},
        {bmzpbh: "1465",trueName: "胡红叶",yuanXiaoName: "宿迁学院",zpMingCheng: "红房子"},
        {bmzpbh: "1466",trueName: "蔡希彤",yuanXiaoName: "南京传媒学院",zpMingCheng: "古桥静影·屋舍守望"},
        {bmzpbh: "1651",trueName: "王倩倩",yuanXiaoName: "南京师范大学",zpMingCheng: "黄龙岘"},
        {bmzpbh: "1662",trueName: "芮芷玥",yuanXiaoName: "南京师范大学",zpMingCheng: "今日云景好·水绿人闲乐"},
        {bmzpbh: "1668",trueName: "邹佳凝",yuanXiaoName: "南京师范大学",zpMingCheng: "佘村风景"},

      ], //优秀奖
      tableData5: [{trueName: "陆庆龙",yuanXiaoName: "南京大学"},
        {trueName: "沈  颖",yuanXiaoName: "东南大学"},
        {trueName: "刘  灏",yuanXiaoName: "南京师范大学"},
        {trueName: "杜  拙",yuanXiaoName: "南京师范大学"},
        {trueName: "封加樑",yuanXiaoName: "南京师范大学"},
        {trueName: "商  桦",yuanXiaoName: "南京师范大学"},
        {trueName: "刘  伟",yuanXiaoName: "南京艺术学院"},
        {trueName: "刘  波",yuanXiaoName: "南京艺术学院"},
        {trueName: "刘  波",yuanXiaoName: "南京艺术学院"},
        {trueName: "刘  春",yuanXiaoName: "南京艺术学院"},
        {trueName: "沈  宁",yuanXiaoName: "南京艺术学院"},
        {trueName: "周  勇",yuanXiaoName: "南京艺术学院"},
        {trueName: "钱文君",yuanXiaoName: "南京艺术学院"},
        {trueName: "黄  鸣",yuanXiaoName: "南京艺术学院"},
        {trueName: "黄雯君",yuanXiaoName: "南京艺术学院"},
        {trueName: "孙文烨",yuanXiaoName: "江苏第二师范学院"},
        {trueName: "平  莉",yuanXiaoName: "南京特殊教育师范学院"},
        {trueName: "田思峰",yuanXiaoName: "南京特殊教育师范学院"},
        {trueName: "张  潇",yuanXiaoName: "南京特殊教育师范学院"},
        {trueName: "陈  杰",yuanXiaoName: "南京特殊教育师范学院"},
        {trueName: "厉  锐",yuanXiaoName: "南京传媒学院"},
        {trueName: "任可欣",yuanXiaoName: "南京传媒学院"},
        {trueName: "孙  肯",yuanXiaoName: "南京传媒学院"},
        {trueName: "李  琪",yuanXiaoName: "南京传媒学院"},
        {trueName: "张  震",yuanXiaoName: "南京传媒学院"},
        {trueName: "孙  浩",yuanXiaoName: "东南大学成贤学院"},
        {trueName: "张  浩",yuanXiaoName: "东南大学成贤学院"},
        {trueName: "耿广可",yuanXiaoName: "江苏经贸职业技术学院"},
        {trueName: "吴  军",yuanXiaoName: "江苏财经职业技术学院"},
        {trueName: "孙如静",yuanXiaoName: "江苏师范大学"},
        {trueName: "惠  剑",yuanXiaoName: "江苏师范大学"},
        {trueName: "蔡芝竖",yuanXiaoName: "江苏师范大学"},
        {trueName: "石  峰",yuanXiaoName: "江苏建筑职业技术学院"},
        {trueName: "李  晨",yuanXiaoName: "江苏建筑职业技术学院"},
        {trueName: "肖永杰",yuanXiaoName: "江苏建筑职业技术学院"},
        {trueName: "汪明镜",yuanXiaoName: "江苏建筑职业技术学院"},
        {trueName: "程  新",yuanXiaoName: "江苏建筑职业技术学院"},
        {trueName: "李东升",yuanXiaoName: "常州大学"},
        {trueName: "吴传景",yuanXiaoName: "常州工业职业技术学院"},
        {trueName: "高  萍",yuanXiaoName: "苏州科技大学"},
        {trueName: "王海彬",yuanXiaoName: "苏州工艺美术职业技术学院"},
        {trueName: "张民生",yuanXiaoName: "苏州工艺美术职业技术学院"},
        {trueName: "曹春辉",yuanXiaoName: "苏州工艺美术职业技术学院"},
        {trueName: "周  颖",yuanXiaoName: "苏州健雄职业技术学院"},
        {trueName: "杨  渝",yuanXiaoName: "南通大学"},
        {trueName: "丁国凯",yuanXiaoName: "南通师范高等专科学校"},
        {trueName: "刘  琰",yuanXiaoName: "南通师范高等专科学校"},
        {trueName: "高广毅",yuanXiaoName: "南通师范高等专科学校"},
        {trueName: "姜  超",yuanXiaoName: "连云港师范高等专科学校"},
        {trueName: "苏  梅",yuanXiaoName: "淮阴师范学院"},
        {trueName: "张  逊",yuanXiaoName: "淮阴工学院"},
        {trueName: "耿金龙",yuanXiaoName: "盐城师范学院"},
        {trueName: "刘晓鑫",yuanXiaoName: "盐城幼儿师范高等专科学校"},
        {trueName: "李  玫",yuanXiaoName: "扬州大学"},
        {trueName: "宗建成",yuanXiaoName: "江苏旅游职业学院"},
        {trueName: "陈  权",yuanXiaoName: "镇江市高等专科学校"},
        {trueName: "孔  光",yuanXiaoName: "宿迁学院"},
        {trueName: "邵士德",yuanXiaoName: "宿迁学院"},
        {trueName: "徐  淮",yuanXiaoName: "宿迁学院"},
      ], //优秀指导老师（第三届新加）
      currentPage: 1,
      highr: document.body.clientHeight - 235,
      page: 1,
      limit: 10,
      total: 0,
    };
  },
  components: {
    headers,
    footers,
  },

  mounted() {
    // this.highr = document.body.clientHeight - 265;
    // this.getCpVoteVoList();
  },

  methods: {
    //刷新数据
    // getCpVoteVoList() {
    //   this.$axios
    //     .get(
    //       "http://47.111.169.186:8870/njdqhxc/api/vote/getFirstTrialList",
    //       {
    //         params: {
    //           page: this.page,
    //           limit:this.limit,
    //           data:{}
    //         },
    //       }
    //     )
    //     .then((res) => {
    //       console.log(res.data);
    //       if (res.data.code == "200") {
    //        this.total = res.data.data.total
    //        this.tableData = res.data.data
    //       }
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },
  },
};
</script>
  <style>
.bottomb {
  width: 100%;
  height: 30px;
  background: #fff;
  line-height: 30px;
  text-align: center;
}
.appraise_hears {
  margin: auto;
  height: 65px;
  border-bottom: #efefef solid 1px;
  position: relative;
}
.appraise_hears img {
  width: 191px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -15.5px;
  margin-left: -95.5px;
}
.main12301 {
  width: 1200px;
  background-color: #ffffff;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0;
  position: relative;
  overflow-x: hidden;
  overflow-y: scroll;
}
.main12301::-webkit-scrollbar {
  display: none;
}
.cuosn {
  width: 100%;
  height: 42px;
  position: relative;
  margin-bottom: 10px;
}
.cuosn img {
  width: 272px;
  height: 42px;
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -136px;
}
.tabss {
  overflow-x: hidden !important;
  overflow-y: scroll !important;
}
.tabss::-webkit-scrollbar {
  display: none;
}
.el-table__body-wrapper::-webkit-scrollbar {
  width: 0;
  background: rgba(213, 215, 220, 0.3);
  border: none;
}
.el-table__body-wrapper::-webkit-scrollbar-track {
  border: none;
}
.el-table th.gutter {
  display: none;
  width: 0;
}
.el-table colgroup col[name="gutter"] {
  display: none;
  width: 0;
}

.el-table__body {
  width: 100% !important;
}
</style>
